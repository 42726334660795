import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { iconButtonClasses } from '@mui/material/IconButton';
import { Case } from '../../interfaces/case';

interface Props {
  item: Case;
}

const CaseCardItem: React.FC<Props> = ({ item }) => {
  return (
    <Box
      sx={{
        px: 1,
        py: 4,
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: 'background.paper',
          borderRadius: '16px',
          width: '100%',
          transition: (theme) => theme.transitions.create(['box-shadow']),
          '&:hover': {
            boxShadow: 2,
            [`& .${iconButtonClasses.root}`]: {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
            },
          },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: { xs: 'unset', sm: 'center' },
          paddingLeft: { xs: 1, sm: 10 },
          gap: '2rem',
          minHeight: '30rem',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            lineHeight: 0,
            overflow: 'hidden',
            borderRadius: '16px',
          }}
        >
          <img
            src={item.cover}
            alt={'Case ' + item.id}
            style={{
              borderRadius: '16px',
              width: '100%',
              height: 'auto',
              maxWidth: 300,
            }}
          />
        </Box>
        <Box sx={{ mt: { xs: 2, sm: 0 } }}> 
          <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: { xs: 300, sm: 450, md: 550 } }}>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'justify', fontSize: { sm: 14 }, width: '100%' }}>
              {item.content}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 4, textAlign: 'right', fontSize: { sm: 14 } }}>
            <strong>
              {item.name}
              <br />
              {item.company}
            </strong>
            <br />
            {item.url}
            <br />
            {item.local}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseCardItem;
