import { FC } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledButton } from '../styled-button';
import { Link as ScrollLink } from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const FirstSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXSScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChatClick = () => {
    window.open('https://tawk.to/chat/5d95168fdb28311764d6fbcf/default', '_blank');
  };

  return (
    <Container>
      <Grid
        container
        spacing={1}
        sx={{
          pb: { xs: 8, md: 10 },
          pt: { xs: 15, sm: 18, md: 15 },
          px: { md: 2, lg: 0 },
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={3}
          sm={4}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            order: { xs: 1, sm: 2 },
          }}
        >
          <Box
            sx={{ lineHeight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: { sm: 4, md: 'unset'} }}
            height={{ xs: 'auto', md: 600 }}
            width={{ xs: '100%', md: 600 }}
          >
            <img
              src="/images/first-slide-img (1).png"
              style={{
                width: isSmallScreen && !isXSScreen ? '450px' : ( isXSScreen ? '220px' : '600px'),
                height: 'auto',
              }}
              alt="laptop sisdm"
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={7}
          md={7}
          sx={{
            order: { xs: 2, sm: 1 },
          }}
        >
          <Box
            sx={{
              textAlign:'left',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#f1faff',
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: { xs: 32, sm: 32, md: 47 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                Gestão Simplificada,
                <br />
                Empresa Competitiva
              </Typography>
            </Box>
            <Box sx={{ mb: 4, width: { xs: '100%', sm: '75%' }, display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ color: '#e0eaff', lineHeight: 1.6, fontSize: { xs: 13, sm: 13, md: 16, xl: 18 }, textAlign: { xs: 'justify', sm: 'left' } }}>
                {
                  'O Sisdm é um ERP focado em otimizar processos entre os diversos setores da empresa, dando ganho de tempo e produtividade aos seus usuários. Com poucas pessoas operando o sistema, é possível gerenciar toda a cadeia produtiva e os processos administrativos de forma simples, eficaz e integrada.'
                }
              </Typography>
            </Box>
            <Box sx={{ '& button': { mr: 2, mb: 1 } }}>
              <StyledButton color="primary" size="large" variant="contained" onClick={handleChatClick}>
                Solicitar Orçamento
              </StyledButton>
              <ScrollLink to="about" spy={true} smooth={true} offset={0} duration={350}>
                <StyledButton color="primary" size="large" variant="outlined">
                  Saiba mais
                </StyledButton>
              </ScrollLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FirstSlide;
