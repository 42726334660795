import { FC, ReactElement, useState } from 'react'
import { SolutionModal } from '../solution-modal'

import { Box, Typography } from '@mui/material'
import { StyledButton } from '../styled-button'
import { CalculateOutlined } from '@mui/icons-material'

interface SolutionsItemsProps {
  icon: ReactElement
  color: string
  label: string
  modalContent: string
  resources: {
    title: string
    icon: ReactElement
  }[]
}

interface SolutionsProps {
  item: SolutionsItemsProps
}

const SolutionCard: FC<SolutionsProps> = ({ item }) => {
  const [itemModal, setItemModal] = useState({
    show: false,
    modalProps: {
      color: '',
      headerItem: { title: '', icon: <CalculateOutlined fontSize="large" /> },
      content: '',
      resources: [{ title: '', icon: <CalculateOutlined fontSize="large" /> }],
    },
  })
  const { icon, color, label, modalContent, resources } = item

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setItemModal({
      show: false,
      modalProps: {
        color: '',
        headerItem: { title: '', icon: <CalculateOutlined fontSize="large" /> },
        content: '',
        resources: [{ title: '', icon: <CalculateOutlined fontSize="large" /> }],
      },
    })
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        mb: 0,
        boxShadow: '1px 1px 6px 4px #f4f4f4',
        borderRadius: '16px',
        padding: '1rem',
        width: { xs: 120, sm: 180 },
        // minWidth: '13rem',
        color: color,
        '&:hover': {
          boxShadow: '1px 1px 6px 4px #E7E8EC',
        },
      }}
    >
      {itemModal && itemModal.show && (
        <SolutionModal
          color={itemModal.modalProps.color}
          headerItem={itemModal.modalProps.headerItem}
          content={itemModal.modalProps.content}
          resources={itemModal.modalProps.resources}
          open={itemModal && itemModal.show}
          onClose={handleClose}
        />
      )}
      <Box
        sx={{
          border: `4px solid ${color}`,
          borderRadius: '100px',
          padding: '0.3rem',
          width: 80,
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '1rem auto',
          color: 'text.disabled',
        }}
      >
        {icon}
      </Box>
      <Typography variant="h5">Módulo</Typography>
      <Typography sx={{ mb: { xs: 1, md: 2 }, fontSize: { xs: 19, sm: 31 }, fontWeight: 'bold' }}>{label}</Typography>
      <StyledButton
        color={color}
        size="large"
        variant="solution"
        onClick={() =>
          setItemModal({
            show: true,
            modalProps: {
              color: color,
              headerItem: { title: label, icon: icon },
              content: modalContent,
              resources: resources,
            },
          })
        }
      >
        Conhecer
      </StyledButton>
    </Box>
  )
}

export default SolutionCard
