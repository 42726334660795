import { FC, forwardRef, ReactElement, Ref } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, Grid } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

interface SolutionModalProps {
  color: string
  headerItem: {
    title: string
    icon: ReactElement
  }
  content: string
  resources?: {
    title: string
    icon: ReactElement
  }[]
  open: boolean
  onClose: () => void
}

const SolutionModal: FC<SolutionModalProps> = ({ color, headerItem, content, resources, open, onClose }) => {
  const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: ReactElement<any, any>
    },
    ref: Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const parseContent = (text: string) => {
    return text.split(';').map((line, index) => (
      <span key={index} style={{ display: 'block', marginBottom: '15px' }}>
        {line
          .trim()
          .split(/(<b>.*?<\/b>)/g)
          .map((part, i) =>
            part.startsWith('<b>') && part.endsWith('</b>') ? (
              <b key={i}>{part.slice(3, -4)}</b>
            ) : (
              <span key={i}>{part}</span>
            )
          )}
      </span>
    ))
  }

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: {xs: 1, sm: 2 },
          backgroundColor: color,
          color: 'primary.contrastText',
          boxShadow: 3,
          borderRadius: '0 0 8px 8px',
        }}
      >
        <Box
          sx={{
            border: `4px solid #FFF`,
            borderRadius: '100px',
            padding: '0.3rem',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'primary.contrastText',
          }}
        >
          {headerItem.icon}
        </Box>
        <DialogTitle sx={{ fontSize: { xs: '1.4rem', sm: '1.8rem' }, marginTop: 2 }}>
          {`${resources ? 'Módulo ' : ''}${headerItem.title}`}
        </DialogTitle>
      </Box>
      <DialogContent sx={{ p: 4 }}>
        <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
          {resources ? content : parseContent(content)}
        </Typography>
        <Divider sx={{ m: 2 }} />

        {resources && (
          <>
            <Typography variant="h4" sx={{ color: color, textAlign: 'center', mb: 1 }}>
              Alguns Recursos
            </Typography>

            <Grid container spacing={2}>
              {resources.map((resource, index) => (
                <Grid item xs={6} sm={4} key={index}>
                  <Box
                    sx={{
                      p: { xs: 1, sm: 2 },
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: color,
                      borderRadius: 1,
                      color: 'primary.contrastText',
                      boxShadow: 3,
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <Box sx={{ mr: { xs: 0.5, sm: 2 } }}>{resource.icon}</Box>
                    <Typography variant="body1" sx={{ fontSize: { xs: 9, sm: '0.9rem' } }}>
                      {resource.title}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ mr: 2 }}>
        <Button onClick={onClose} autoFocus sx={{ color: color }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SolutionModal
