import { FC, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const SecondSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const [dimensions, setDimensions] = useState({ width: 600, height: 600 });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const handleResize = () => {
      if (parentHeight && parentWidth) {
        const width = isSmallScreen ? parentWidth * 0.40 : parentWidth * 0.35;
        const height = parentHeight;
        setDimensions({ width, height });
      } else {
        const width = window.innerWidth * 0.35;
        const height = window.innerHeight;
        setDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentHeight, parentWidth, isSmallScreen]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={0} sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              marginLeft: 0,
              overflow: 'hidden',
              pt: 12,
            }}
          >
            <img
              src="/images/second-slide-xs.png"
              width="250px"
              height='auto'
              alt="áreas de atuação"
            />
          </Box>
        </Grid>

        <Grid item sm={5} sx={{ position: 'relative', display: { xs: 'none', sm: 'block' } }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: -1,
              overflow: 'hidden',
            }}
          >
            <img
              src="/images/second-slide-img.png"
              width={dimensions.width}
              height={dimensions.height}
              alt="áreas de atuação"
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={7} sx={{ pb: { xs: 0, sm: 10 }, pt: { xs: 0, sm: 20 } }}>
          <Box
            sx={{
              textAlign: 'left',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: { xs: 'center', sm: 'unset' },
              padding: { xs: 0, sm: '2rem' },
              color: '#f1faff',
            }}
          >
            <Box sx={{ mb: { xs: 1, sm: 3 }, mt: 0 }}> 
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: { xs: 32, sm: 47 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                Áreas de Atuação
              </Typography>
            </Box>
            <Box sx={{ mb: 4, width: '75%' }}>
              <Typography sx={{ color: '#e0eaff', lineHeight: 1.6, fontSize: { xs: 14, sm: 18 } }} component="div">
                <ul style={{ paddingLeft: 20 }}>
                  <li>Ferramentaria / Usinagem</li>
                  <li>Indústria Produção Seriada</li>
                  <li>Indústria de Fabricação sob Encomenda (projetos industriais)</li>
                  <li>Metal Mecânico</li>
                  <li>Atacadistas e Distribuidoras com Emissão de NFe Modelo 55</li>
                  <li>Outras Indústrias de Serviço</li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SecondSlide;
