import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';
import typography from './typography';
import paletteBase from './palette-base';
import paletteLight from './palette-light';
import paletteDark from './palette-dark';
import shadows from './shadows';

// Função para criar o tema com breakpoints personalizados
const createTheme = (darkMode?: boolean): Theme => {
  const palette = darkMode ? { ...paletteBase, ...paletteDark } : { ...paletteBase, ...paletteLight };

  return createMuiTheme({
    palette,
    typography,
    shadows,
    breakpoints: {
      values: {
        xs: 0,
        sm: 820,
        md: 1180,
        lg: 1920,
        xl: 2000,
      },
    },
  });
};

const theme = createTheme(false);

export { paletteBase, paletteLight, paletteDark, typography, shadows };
export default theme;
