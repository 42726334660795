import { FC, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledButton } from '../styled-button';
import { SolutionModal } from '../solution-modal';
import { AccountTree } from '@mui/icons-material';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const FourthSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const [resourcesModal, setResourcesModal] = useState({
    show: false,
    modalProps: {
      color: '#2F5BC5',
      headerItem: { title: 'Principais Recursos', icon: <AccountTree fontSize="large" /> },
      content:
        '<b>Gestão de Pedidos,</b> com opção de faturamento total/parcial, controle dos saldos a faturar, e replicação de pedidos.; <b>Módulo de Retorno para Industrializações.</b> Receba as notas do fornecedor e retorne os insumos automaticamente, controlando o saldo a retornar para cada nota. Fature os serviços da industrialização de forma agrupada ou individualizada cfe. os itens retornados - no módulo de industrialização, você define a melhor forma de faturar.; <b>Fluxo de Caixa Gerencial</b> com projeção de até 120 dias, com controle de inadimplência na mesma tela, e acompanhamento da evolução do fluxo via gráfico.;<b>Geração automática da necessidade de compra</b> de insumos/componentes para a produção, com base no que está pendente para produzir, ou com base nos pedidos de venda já digitados;<b>Apontamento de Horas</b> simplificado na produção, entregando o custo total de cada OP ou Projeto, em tela gerencial de fechamento.;<b>Plano de Contas Financeiro</b> para acompanhamento de relatórios e consultas gerenciais, de forma simples e eficaz.;',
    },
  });

  return (
    <Container maxWidth="lg">
      {resourcesModal && resourcesModal.show && (
        <SolutionModal
          color={resourcesModal.modalProps.color}
          headerItem={resourcesModal.modalProps.headerItem}
          content={resourcesModal.modalProps.content}
          open={resourcesModal && resourcesModal.show}
          onClose={() => setResourcesModal((prevProps) => ({ ...prevProps, show: false }))} />
      )}
      <Grid 
        container 
        spacing={2}
        sx={{ 
          pb: { sm: 6, md: 10 }, 
          pt: { xs: 15, sm: 8, md: 13 }, 
          px: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: { xs: 'center', sm: 'flex-start' },
        }}
      >
        <Grid 
          item 
          xs={12}
          sm={6}
          md={5}
          sx={{ 
            position: 'relative', 
            display: 'flex', 
            justifyContent: 'center', 
            pr: { md: 2 },
          }}
        >
          <Box
            sx={{
              lineHeight: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: { xs: 220, sm: 400, md: 550 },
              width: { xs: 220, sm: 400, md: 550 },
            }}
          >
            <img
              src="/images/fourth-slide-img.png"
              style={{
                width: '100%', 
                height: 'auto',
              }}
              alt="laptop sisdm"
            />
          </Box>
        </Grid>

        <Grid 
          item 
          xs={12}
          sm={6}
          md={7}
          sx={{ 
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'left'},
              height: '100%',
              margin: { xs: 'unset', sm: '10rem' },
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#f1faff',
              marginLeft: { xs: 0, md: 15 },
              pl: { xs: 0, md: 5 },
            }}
          >
            <Box sx={{ mb: { xs: 2, sm: 3 } }}>
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: { xs: 32, sm: 38, md: 47 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                <Typography
                  component="mark"
                  sx={{
                    position: 'relative',
                    color: 'primary.contrastText',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    backgroundColor: 'unset',
                  }}
                >
                  Maximize seus Resultados com
                </Typography>
                <br />
                Esforço Mínimo
              </Typography>
            </Box>
            <Box sx={{ '& button': { mr: { xs: 0, sm: 2 } }, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <StyledButton
                color="primary"
                size="large"
                variant="contained"
                onClick={() => setResourcesModal((prevProps) => ({ ...prevProps, show: true }))}>
                Alguns dos principais recursos
              </StyledButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FourthSlide;
