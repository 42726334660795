import React, { FC, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledButton } from '../styled-button';
import { Cloud } from '@mui/icons-material';
import { SolutionModal } from '../solution-modal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const ThirdSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const [cloudModal, setCloudModal] = useState({
    show: false,
    modalProps: {
      color: '#2F5BC5',
      headerItem: { title: 'SISDM Cloud', icon: <Cloud fontSize="large" /> },
      content:
        '<b>Mobilidade</b>: Acesso ao sistema de qualquer lugar com Internet disponível, 24 horas por dia, 7 dias da semana.; <b>Comodidade</b>: Já vem com todos os aplicativos necessários instalados e configurados - só conectar e trabalhar.; <b>Economia</b>: Evite gastos com servidor, licenças de uso, sistemas operacionais, antivírus e profissionais de infra estrutura.;<b>Agilidade</b>: Todas as atualizações necessárias são feitas pela equipe Sisdm Tech.;<b>Segurança</b>: Ambiente protegido com várias camadas de segurança contra acessos indesejados e antivírus sempre atualizado.;<b>Tecnologia</b>: O ambiente dispõe de ferramentas oficiais e sempre atualizadas, melhorando a experiência do usuário regularmente.;',
    },
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXSScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      {cloudModal.show && (
        <SolutionModal
          color={cloudModal.modalProps.color}
          headerItem={cloudModal.modalProps.headerItem}
          content={cloudModal.modalProps.content}
          open={cloudModal.show}
          onClose={() => setCloudModal((prevProps) => ({ ...prevProps, show: false }))} 
        />
      )}
      <Grid
        container
        spacing={1}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center', 
          pb: { xs: 0, sm: 8, md: 10 },
          pt: { xs: 2, sm: 8, md: 8 },
        }}
      >
        <Grid
          item
          sm={5}
          md={4.5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            order: { xs: 1, sm: 2 },
          }}
        >
          <Box
            sx={{
              lineHeight: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: 550,
              height: isSmallScreen && !isXSScreen ? '500px' : ( isXSScreen ? '400px' : '600px'),
              ml: {xs: -2, sm: 0}
            }}
          >
            <img
              src="/images/third-slide-img (2).png"
              style={{
                width: isSmallScreen && !isXSScreen ? '380px' : ( isXSScreen ? '220px' : '500px'),
                height: 'auto',
              }}
              alt="Sisdm Cloud"
            />
          </Box>
        </Grid>

        <Grid
          item
          sm={7}
          md={7}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', 
            order: { xs: 2, sm: 1 },
            mt: { xs: -10, sm: 'unset' }
          }}
        >
          <Box
            sx={{
              textAlign: 'left',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: { xs: 'center', sm: 'unset' },
              color: '#f1faff',
              padding: '0 1rem',
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                component="h2"
                sx={{
                  fontSize: { xs: 32, md: 47 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                SISDM Cloud
              </Typography>
              <Typography
                component="h2"
                sx={{
                  fontSize: { xs: 32, md: 47 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                  width: { xs: '100%', md: 'auto' },
                }}
              >
                Seu sistema na Nuvem!
              </Typography>
            </Box>
            <Box sx={{ mb: 4, width: '75%', display: { xs: 'flex', sm: 'unset' }, justifyContent: { xs: 'center', sm: 'unset' } }}>
              <Typography sx={{ color: '#e0eaff', lineHeight: 1.6, fontSize: { sm: 13, md: 18 } }} component="div">
                <ul
                  style={{
                    paddingLeft: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    listStyleType: 'disc',
                    margin: 0,
                  }}
                >
                  <li style={{ marginBottom: '2px' }}>Mobilidade</li>
                  <li style={{ marginBottom: '2px' }}>Economia</li>
                  <li style={{ marginBottom: '2px' }}>Comodidade</li>
                  <li style={{ marginBottom: '2px' }}>Agilidade</li>
                  <li style={{ marginBottom: '2px' }}>Segurança</li>
                  <li style={{ marginBottom: '2px' }}>Tecnologia</li>
                </ul>
              </Typography>
            </Box>
            <Box sx={{ '& button': { mr: 2 } }}>
              <StyledButton
                color="primary"
                size="large"
                variant="contained"
                onClick={() => setCloudModal((prevProps) => ({ ...prevProps, show: true }))} 
              >
                Saiba mais
              </StyledButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThirdSlide;
